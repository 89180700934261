import axios, { AxiosError } from 'axios';
import { handleError } from 'utils/error';

import Tracking from './tracking';

const paymentSuccessful = async (props: {
  code: string;
  orderId: string;
  data: {
    currencyCode: string;
    paymentMethod: string;
    transactionId: string;
    transactionTotal: number;
    transactionAffiliation: string;
    transactionProducts: {
      sku: string;
      name: string;
      category: string;
      price: string;
      quantity: number;
    }[];
  };
}) => {
  try {
    Tracking.logEvent({
      event: 'paymentSuccessful',
      ...props.data,
    });
  } catch (error) {
    try {
      Tracking.logEvent({
        event: 'paymentSuccessful',
        ...props.data,
      });
    } catch (error) {
      handleError(error as AxiosError);
    }
    handleError(error as AxiosError);
  }
};

export const Events = {
  paymentSuccessful,
};
