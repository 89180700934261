import React, { FC } from 'react';
import { Props, Styles } from 'react-modal';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { Modal, Text } from 'components';
import PaymentDialogOrderSummary from './OldPaymentDialogOrderSummary';
import PaymentsForm from './OldPaymentsForm';
import EmailInput from './OldEmailInput';
import { AppState } from 'state/types';
import PaymentSteps from './OldPaymentSteps';
import SecureFooter from 'assets/icons/payments/secure-footer.svg';
import { mobile, useQuery } from 'styles/breakpoints';
import { useQuizData } from 'utils/hooks';
import { OrderSummarySubItem } from 'types/checkout';
import { Product } from 'types/product';

interface PaymentDialogProps {
  selectedProduct: Product | null;
  onSuccess: (data: any) => Promise<void>;
  orderSummarySubItems?: OrderSummarySubItem[];
}

const ContentContainer = styled.div`
  padding: 3rem 3rem 1.5rem 3rem;

  @media ${mobile} {
    padding: 3.25rem 1rem 1rem 1rem;
  }
`;

const PaymentStepsContainer = styled.div`
  max-width: 15.75rem;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  text-align: center;
  padding: 1.5rem 0;
`;

const SecureFooterStyled = styled(SecureFooter)`
  margin-top: 1rem;
  width: 100%;
`;

const EmailInputContainer = styled.div`
  padding-bottom: 0.375rem;
`;

const PaymentDialog: FC<PaymentDialogProps & Props> = ({
  selectedProduct,
  onSuccess,
  orderSummarySubItems,
  ...props
}) => {
  const { user } = useSelector((state: AppState) => state.user);

  const data = useQuizData('payments');

  const { isMobile } = useQuery();

  const customStyle: Styles = {
    content: {
      padding: 0,
    },
  };

  const mobileStyle: Styles = {
    content: {
      padding: 0,
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      borderRadius: 0,
    },
  };

  return (
    <Modal
      width="27.44rem"
      style={isMobile ? mobileStyle : customStyle}
      {...props}
    >
      <ContentContainer>
        <PaymentStepsContainer>
          <PaymentSteps
            firstStepTitle={data?.firstStepTitle}
            secondStepTitle={data?.secondStepTitle}
            thirdStepTitle={data?.thirdStepTitle}
          />
        </PaymentStepsContainer>
        <TitleContainer>
          <Text type="h4700">{data?.title}</Text>
        </TitleContainer>
        <EmailInputContainer>
          <EmailInput
            label={data?.emailInputLabel}
            email={user?.email ?? 'jon@doe.com'}
          />
        </EmailInputContainer>
        <PaymentDialogOrderSummary
          subItems={orderSummarySubItems}
          selectedProduct={selectedProduct}
        />
        <PaymentsForm onSuccess={onSuccess} />
        <SecureFooterStyled />
      </ContentContainer>
    </Modal>
  );
};

export default PaymentDialog;
